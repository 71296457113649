import axios from 'axios';

const isDevelopment = false;  // Hardcoded to false for production
const baseURL = isDevelopment 
  ? 'http://localhost:8000'
  : 'https://fp8.ai/api';  // Add /api here

const axiosInstance = axios.create({
    baseURL: baseURL,
    // timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true  // Important for handling cookies/sessions
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;