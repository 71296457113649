import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from '../App';
import bitoodaIcon from "../components/assets/bitoodaIcon.png";

function Navbar() {
  const { isAuthenticated, handleLogout } = useContext(AuthContext);
  const [sticky, setSticky] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [consultingTitles, setConsultingTitles] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => setSticky(window.scrollY > 0);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    fetch("/assets/consulting.json")
      .then((response) => response.json())
      .then((data) => setConsultingTitles(data.map((item) => item.title)))
      .catch((error) => console.error("Error fetching consulting titles:", error));
  }, []);

  const handleOurTeamClick = () => {
    if (location.pathname === "/") {
      const ourTeamSection = document.getElementById("our-team-section");
      if (ourTeamSection) {
        ourTeamSection.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate("/", { replace: false });
      setTimeout(() => {
        const ourTeamSection = document.getElementById("our-team-section");
        if (ourTeamSection) {
          ourTeamSection.scrollIntoView({ behavior: "smooth" });
        }
      }, 300);
    }
  };

  const navItems = [
    { name: "AI Marketplace", externalLink: "https://fpinfinity.xyz/" },
    { name: "Consulting", href: "/consulting" },
    { name: "Research", href: "/research" },
    // { name: "Market Education", href: "/research-overviews" },
    { name: "Our Team", action: handleOurTeamClick },
  ];

  const authButtons = (
    <div className="flex items-center space-x-4">
      {isAuthenticated ? (
        <button
          onClick={handleLogout}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-purple-700 transition duration-300"
        >
          Logout
        </button>
      ) : (
        <>
          <button
            onClick={() => navigate("/login")}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-purple-700 transition duration-300"
          >
            Login
          </button>
          <button
            onClick={() => navigate("/register")}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-purple-700 transition duration-300"
          >
            Sign Up
          </button>
        </>
      )}
    </div>
  );

  return (
    <div
      className={`max-w-full fixed top-0 left-0 right-0 z-50 ${
        sticky ? "shadow-md bg-[#1b0f3c] text-white" : "bg-transparent"
      } transition-all duration-300 ease-in-out`}
    >
      <div className="flex items-center justify-between py-4 px-4 md:px-10 lg:px-20 text-white">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate("/")}
        >
          {/* <img src={bitoodaIcon} alt="Logo" className="w-15 h-10 shadow-lg rounded-lg" /> */}
          <span className="text-4xl 2xl:text-6xl font-bold px-2">FP8</span>
        </div>

        {/* Navigation Links */}
        <div className="hidden lg:flex lg:space-x-6 2xl:text-3xl">
          {navItems.map((item) =>
            item.externalLink ? (
              <a
                key={item.name}
                href={item.externalLink}
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 rounded hover:bg-gray-700 transition duration-200 text-white no-underline"
              >
                {item.name}
              </a>
            ) : item.name === "Our Team" ? (
              <button
                key={item.name}
                onClick={item.action}
                className="p-2 rounded hover:bg-gray-700 transition duration-200"
              >
                {item.name}
              </button>
            ) : item.name === "Consulting" ? (
              <div key={item.name} className="flex flex-col items-center">
                <div className="flex items-center relative">
                  <button
                    onClick={() => navigate("/consulting")}
                    className="p-2 rounded hover:bg-gray-700 transition duration-200 flex items-center"
                  >
                    {item.name}
                  </button>
                  <span
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    className="hover:cursor-pointer"
                  >
                    {dropdownOpen ? "▲" : "▼"}
                  </span>
                </div>
                {dropdownOpen && (
                  <div className="absolute top-20 bg-[#140530] text-white mt-6 w-64 2xl:w-[30rem] shadow-lg rounded-lg">
                    {consultingTitles.map((title, index) => (
                      <a
                        key={index}
                        href={`/consulting#section-${index}`}
                        className="block px-4 py-2 hover:bg-gray-700 text-white no-underline"
                      >
                        {title}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <a
                key={item.name}
                href={item.href}
                className="p-2 rounded hover:bg-gray-700 transition duration-200 text-white no-underline"
              >
                {item.name}
              </a>
            )
          )}
          {authButtons}
        </div>

        <div className="lg:hidden">
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-white focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-4 6h-8"
              />
            </svg>
          </button>
        </div>
      </div>

      {menuOpen && (
        <div className="lg:hidden flex flex-col space-y-2 mt-2 px-4">
          {navItems.map((item) =>
            item.externalLink ? (
              <a
                key={item.name}
                href={item.externalLink}
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 rounded bg-gray-700 hover:bg-gray-700 transition duration-200 text-white no-underline"
              >
                {item.name}
              </a>
            ) : item.name === "Our Team" ? (
              <button
                key={item.name}
                onClick={item.action}
                className="p-2 rounded bg-gray-700 hover:bg-gray-700 transition duration-200 text-white no-underline"
              >
                {item.name}
              </button>
            ) : (
              <a
                key={item.name}
                href={item.href}
                className="p-2 rounded bg-gray-700 hover:bg-gray-700 transition duration-200 text-white no-underline"
              >
                {item.name}
              </a>
            )
          )}
          {authButtons}
        </div>
      )}
    </div>
  );
}

export default Navbar;