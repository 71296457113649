import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { postService } from './PostService';
import './ContentPreview.css';
import Navbar from '../Navbar';
import Footer from '../Footer';

const PostDetail = () => {
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams(); 

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const data = await postService.getPost(id);
                console.log(data);
                setPost(data);
                setLoading(false);
            } catch (err) {
                setError('Failed to load post details');
                setLoading(false);
            }
        };

        fetchPost();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!post) {
        return <div>Post not found</div>;
    }

    return (
        <div className="h-full min-h-screen pt-20 text-white bg-gradient-to-br from-[#57275e] via-black to-[#540860]">
            <Navbar />
        <div className="post-container" style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
            {post.image && (
                <img 
                    src={post.image.startsWith('http') ? post.image : `${window.location.origin}${post.image}`}
                    alt={post.title}
                    style={{ width: '100%', maxHeight: '400px', objectFit: 'cover' }}
                    onError={(e) => console.error("Image loading error:", e)}
                />
            )}

            <h1 style={{ marginTop: '20px' }}>{post.title}</h1>
            
            <div className="description">
                <h3>Description:</h3>
                <p>{post.description}</p>
            </div>

            {post.components && post.components.length > 0 && (
                <div className="components" style={{ margin: '20px 0' }}>
                    <h4>Components:</h4>
                    <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                        {post.components.map((component, index) => (
                            <span 
                                key={index}
                                style={{
                                    background: 'purple',
                                    padding: '5px 10px',
                                    borderRadius: '4px'
                                }}
                            >
                                {component.name}
                            </span>
                        ))}
                    </div>
                </div>
            )}

            <div className="content" style={{ margin: '20px 0' }}>
                <h3>Content:</h3>
                <div 
                dangerouslySetInnerHTML={{ __html: post.content }}
                style={post.contentStyles}
                className="content-preview"
                />
            </div>

            <div className="metadata" style={{ marginTop: '20px', color: '#888', fontSize: '0.9em' }}>
                <p>Created: {new Date(post.created_at).toLocaleDateString()}</p>
                <p>Last updated: {new Date(post.updated_at).toLocaleDateString()}</p>
            </div>
        </div>
        <Footer />
        </div>
    );
};

export default PostDetail;