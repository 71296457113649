import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { postService } from "./PostService";

const InsideResearchPapaerOverviews = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams(); // Get the `id` from the route parameters

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const data = await postService.getPost(id);
        setPost(data);
        setLoading(false);
      } catch (err) {
        setError("Failed to load post details");
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  if (loading) {
    return <div className="text-center text-white">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-white">{error}</div>;
  }

  if (!post) {
    return <div className="text-center text-white">Post not found</div>;
  }

  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center py-20 bg-gradient-to-br from-[#2a0530] via-black to-[#2a0530] text-white">
        <h1 className="text-4xl font-bold mb-8">{post.title}</h1>
        {post.image && (
          <img
            src={post.image}
            alt={post.image}
            className="w-full max-w-3xl rounded-lg mb-8"
          />
        )}
        <div className="text-left max-w-4xl">
          <p className="text-lg leading-relaxed mb-8">
            <span className="font-semibold">Description:</span> {post.description}
          </p>
        </div>
        <div className="flex flex-wrap justify-start gap-2 2xl:text-2xl">
          <h4 className="text-lg font-semibold">Components:</h4>
          {post.components &&
            post.components.map((component, index) => (
              <div
                className="bg-purple-900/70 px-3 py-1 text-[0.9rem] 2xl:text-xl uppercase tracking-wider text-white rounded-full"
                key={index}
              >
                {component.name}
              </div>
            ))}
        </div>
        <div className="text-left max-w-4xl">
          <h2 className="text-2xl font-bold mb-4">Content:</h2>
          <div
            dangerouslySetInnerHTML={{ __html: post.content }}
            style={post.contentStyles}
            className="content-preview"
          />
        </div>
        <p className="text-gray-300 text-sm mt-8">
          Created on: {new Date(post.created_at).toLocaleDateString()}
        </p>
      </div>
      <Footer />
    </>
  );
};

export default InsideResearchPapaerOverviews;
