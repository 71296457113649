import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

// Base Animations
const glowAnimation = keyframes`
  from {
    text-shadow: 0 0 5px #4169E1;
  }
  to {
    text-shadow: 0 0 10px #4169E1, 0 0 15px #4169E1;
  }
`;

const fadeIn = keyframes`
  from { 
    opacity: 0; 
    transform: translateY(20px); 
  }
  to { 
    opacity: 1; 
    transform: translateY(0); 
  }
`;

const pulseAnimation = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(65, 105, 225, 0.4); }
  70% { box-shadow: 0 0 0 15px rgba(65, 105, 225, 0); }
  100% { box-shadow: 0 0 0 0 rgba(65, 105, 225, 0); }
`;

// Additional Animations
const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const sparkleAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const rotateAnimation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const shimmerAnimation = keyframes`
  0% { transform: translateX(-100%) rotate(45deg); }
  100% { transform: translateX(100%) rotate(45deg); }
`;

// Styled Components
const HeaderContainer = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  background-color: #000;
`;

const ParticlesContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const GradientBackground = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom right, #18034f, black, #18034f);
  z-index: 0;
  animation: ${sparkleAnimation} 15s ease-in-out infinite;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 0 20px;
`;

const Title = styled.h1`
  font-size: clamp(4rem, 15vw, 15rem);
  font-weight: bold;
  background: linear-gradient(
    270deg, 
    #4169E1, 
    #1e3a8a, 
    #4169E1, 
    #6495ED
  );
  background-size: 300% 300%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: 
    ${glowAnimation} 3s ease-in-out infinite alternate,
    ${sparkleAnimation} 6s ease-in-out infinite;
  opacity: 0.85; // Slightly reduced opacity
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border: 2px solid transparent;
    border-radius: 15px;
    background: linear-gradient(45deg, #4169E1, transparent) border-box;
    -webkit-mask:
      linear-gradient(#fff 0 0) padding-box, 
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) padding-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    animation: ${rotateAnimation} 4s linear infinite;
    opacity: 0.2; // Slightly reduced border opacity
  }
`;

const Subtitle = styled.p`
  font-size: clamp(1.5rem, 4vw, 7rem);
  font-weight: bold;
  color: white;
  margin-top: 2rem;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${props => props.delay || '0s'};
`;

const Description = styled.p`
  font-size: clamp(1rem, 2vw, 3rem);
  font-weight: bold;
  color: white;
  margin-top: 1.5rem;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 0.5s;
  max-width: 800px;
`;

const ExploreButton = styled.a`
  display: inline-block;
  margin-top: 3rem;
  padding: 1rem 2rem;
  font-size: clamp(1rem, 2vw, 3rem);
  font-weight: 500;
  color: white;
  background: linear-gradient(to bottom right, #4169E1, #1e3a8a);
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  animation: ${pulseAnimation} 3s infinite;
  
  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      45deg,
      transparent,
      rgba(255, 255, 255, 0.1),
      transparent
    );
    transform: rotate(45deg);
    animation: ${shimmerAnimation} 3s linear infinite;
  }

  &:hover {
    transform: scale(1.05) translateY(-3px);
    background: linear-gradient(to bottom left, #4169E1, #1e3a8a);
    box-shadow: 0 10px 20px rgba(65, 105, 225, 0.3);
  }

  &:active {
    transform: scale(0.98);
  }
`;

function Header() {
  useEffect(() => {
    const initParticles = () => {
      if (window.particlesJS) {
        window.particlesJS('particles-js', {
          particles: {
            number: {
              value: 100,
              density: {
                enable: true,
                value_area: 1000
              }
            },
            color: {
              value: ['#4169E1', '#1e3a8a', '#6495ED']
            },
            shape: {
              type: ['circle', 'triangle'],
              stroke: {
                width: 1,
                color: '#4169E1'
              }
            },
            opacity: {
              value: 0.6,
              random: true,
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0.1,
                sync: false
              }
            },
            size: {
              value: 4,
              random: true,
              anim: {
                enable: true,
                speed: 2,
                size_min: 0.1,
                sync: false
              }
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: '#4169E1',
              opacity: 0.4,
              width: 1
            },
            move: {
              enable: true,
              speed: 3,
              direction: 'none',
              random: false,
              straight: false,
              out_mode: 'out',
              bounce: false,
              attract: {
                enable: true,
                rotateX: 600,
                rotateY: 1200
              }
            }
          },
          interactivity: {
            detect_on: 'canvas',
            events: {
              onhover: {
                enable: true,
                mode: 'grab'
              },
              onclick: {
                enable: true,
                mode: 'push'
              },
              resize: true
            },
            modes: {
              grab: {
                distance: 140,
                line_linked: {
                  opacity: 1
                }
              },
              push: {
                particles_nb: 4
              }
            }
          },
          retina_detect: true
        });
      }
    };

    setTimeout(initParticles, 100);

    return () => {
      try {
        if (window.pJSDom) {
          window.pJSDom.forEach(dom => {
            if (dom && dom.pJS && dom.pJS.particles) {
              dom.pJS.particles.array = [];
            }
          });
          window.pJSDom = [];
        }
        
        const canvas = document.querySelector('#particles-js canvas');
        if (canvas) {
          canvas.remove();
        }
      } catch (error) {
        console.log('Cleanup error:', error);
      }
    };
  }, []);

  return (
    <HeaderContainer>
      <ParticlesContainer id="particles-js" />
      <GradientBackground />
      <ContentWrapper>
        <Title>FP8</Title>
        <Subtitle>Redefining Compute as a Commodity</Subtitle>
        <Description>
          Democratizing compute by building a fully transparent two-way marketplace for all things AI.
        </Description>
        <ExploreButton
          href="https://fpinfinity.xyz/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Explore AI MarketPlace
        </ExploreButton>
      </ContentWrapper>
    </HeaderContainer>
  );
}

export default Header;