import './App.css';
import Home from './components/home/Home';
import OurTeamPage from './components/ourteam/OurTeamPage';
import ResearchPage from './components/research/ResearchPage';
import ConsultingPage from './components/consulting/ConsultingPage';
import InsideResearch from './components/research/InsideResearch';
import AddPost from './components/admin/AddPost';
import Login from './components/Login';
import Register from './components/Register';
import PostDetail from './components/admin/PostDetail';
import React, { createContext, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar';
import ResearchPapaerOverviews from './components/admin/ResearchPapaerOverviews';
import InsideResearchPapaerOverviews from './components/admin/InsideResearchPapaerOverviews';
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";

export const AuthContext = createContext(null);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('access');
    setIsAuthenticated(!!token);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    setIsAuthenticated(false);
    window.location.href = '/';
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, handleLogout }}>
      <Router>
        <div className="app-container">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/admin/addpost" element={<AddPost />} />
            <Route path="/ourteam" element={<OurTeamPage />} />
            <Route path="/research" element={<ResearchPage />} />
            <Route path="/research/:id" element={<InsideResearch />} />
            <Route path="/consulting" element={<ConsultingPage />} />
            <Route path="/posts/:id" element={<PostDetail />} />
            <Route path="/research-overviews" element={<ResearchPapaerOverviews />} />
            <Route path="/research-overviews/:id" element={<InsideResearchPapaerOverviews />} />
          </Routes>
        </div>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;